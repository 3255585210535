import styled from 'styled-components'

export const StyledNavbar  = styled.div`
&& Navbar {
    background-color: red;
  }
`;

export const NavBarLogo = styled.img`
    max-width: 70%;
`;	